.App {
  text-align: center;
}

.App-logo {
  width: 30vmin;
  margin-right: auto;
}

.Image-offset {
  margin-left: auto;
  width: 30vmin;
}

@font-face {
  font-family: "Silkscreen";
  src: local("SilkScreen-Regular"),
    url("./fonts/Silkscreen-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Silkscreen-Bold";
  src: local("SilkScreen-Bold"),
    url("./fonts/Silkscreen-Bold.ttf") format("truetype");
}

.Header-title {
  font-family: Silkscreen-Bold;
  font-size: 8vh;
  align-self: center;
  text-align: center;
  color: #d9cdbf;
  margin: 0;
}

.Header {
  display: flex;
  width: 100%;
  height: 15%;
  justify-content: center;
  vertical-align: middle;
  padding-bottom: 3vh;
  padding-top: 3vh;
}
